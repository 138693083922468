import Vuex from "vuex";
import Vue from "vue";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    surveyId: "tlane230524",
    dev: false,
    loading: false,
    userData: {},
    mapData: {
      area: "",
      zoom: 12,
      center: {
        lat: 19.4082954,
        lon: -99.1633882,
      },
      agebShapefile: {},
      agebData: {},
      sectionShape: {},
      deputyData: {},
      mayorData: {},
      indexData: {},
    },
    window: {
      width: 0,
      height: 0,
    },
  },

  getters: {
    connUrl: (state) => {
      if (state.dev) return "http://localhost:8080/";
      else return "https://firebasestorage.googleapis.com/";
    },
  },
  mutations: {
    toggleLoading(state) {
      state.loading = !state.loading;
    },
    setShapeFile(state, shape) {
      state.mapData.agebShapefile = shape;
    },
    setAgebData(state, data) {
      state.mapData.agebData = data;
    },
    updateWindow(state, newData) {
      state.window.width = newData[0];
      state.window.height = newData[1];
    },
    updateUserData(state, newData) {
      state.userData = newData;
      state.mapData.area = newData.area;
    },
    updateMapData(state, newData) {
      state.mapData.urls = newData;
      state.mapData.center = newData.center;
    },
    setSectionShape(state, shape) {
      state.mapData.sectionShape = shape;
    },
    setDeputyData(state, data) {
      state.mapData.deputyData = data;
    },
    setTownData(state, data) {
      state.mapData.mayorData = data;
    },
    setIndexData(state, data) {
      state.mapData.indexData = data;
    },
    clearMapData(state) {
      state.mapData = { zoom: 12 };
    },
  },
  actions: {
    toggleLoading(context) {
      context.commit("toggleLoading");
    },
    updateWindow(context, data) {
      context.commit("updateWindow", data);
    },
    updateUserData(context, data) {
      if (data != null) {
        context.commit("updateUserData", data);
      }
    },
    updateMapData(context, newData) {
      context.commit("updateMapData", newData);
    },
    setSectionShape(context, shape) {
      context.commit("setSectionShape", shape);
    },
    setDeputyData(context, data) {
      context.commit("setDeputyData", data);
    },
    setTownData(context, data) {
      context.commit("setTownData", data);
    },
    setIndexData(context, data) {
      context.commit("setIndexData", data);
    },
    clearMapData(context) {
      context.commit("clearMapData");
    },
    async makeAPICall(context, request) {
      console.log(request);
      let resource = context.state.mapData.urls[request.url];
      if (context.state.dev)
        resource = resource.replace(
          "https://firebasestorage.googleapis.com/",
          "http://localhost:8080/"
        );
      console.log(resource);
      await axios
        .get(resource)
        .then((response) => context.commit(request.mutation, response.data))
        .catch((error) => console.warn(error, request));
    },
  },
});
