import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "leaflet/dist/leaflet.css";
import "@mdi/font/css/materialdesignicons.css";
import firebase from "firebase/app";
import store from "./store/store";

// Web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyA1xM9lwjnSGnB24t9--Qlsu4UE6GNKvHE",
  authDomain: "consulttlp.firebaseapp.com",
  databaseURL: "https://consulttlp-default-rtdb.firebaseio.com",
  projectId: "consulttlp",
  storageBucket: "consulttlp.appspot.com",
  messagingSenderId: "627535189239",
  appId: "1:627535189239:web:4e7d2aea270c06fe67aff2"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
