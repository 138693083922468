<template>
  <v-app id="app" ref="infoBox">
    <v-app-bar min-height="65px" app color="white" style="z-index:9999;" flat>
      <v-container class="py-0 fill-height">
        <v-avatar tile class="mr-10" size="32">
          <v-img :src="logo"> </v-img>
        </v-avatar>

        <div v-if="$route.name != 'Home' && $route.name != 'Encuesta'">
          <v-btn
            v-for="link in links"
            :key="link.title"
            class="font-weight-bold"
            text
            color="black"
            @click="sendTo(link)"
          >
            {{ link.title }}
          </v-btn>
        </div>

        <v-spacer></v-spacer>

        <div v-if="$route.name != 'Home' && userData.admin">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="font-weight-bold"
                color="success"
                dark
                v-bind="attrs"
                v-on="on"
              >
                REGIONES
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="area in areas" :key="area">
                <v-btn
                  class="font-weight-bold"
                  @click="changeArea(area)"
                  text
                  dense
                >
                  {{ area.toUpperCase() }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn class="ml-2" color="primary" dark>
            <router-link style="color: white !important;" :to="'/admin'">
              ADMIN
            </router-link>
          </v-btn>
        </div>

        <v-btn
          class="ml-2"
          color="error"
          @click="logout"
          v-if="$route.name != 'Home' && $route.name != 'Encuesta'"
        >
          <strong>
            CERRAR SESIÓN
          </strong>
        </v-btn>
      </v-container>
    </v-app-bar>

    <v-main class="grey lighten-3" :key="mainKey" v-if="showMain">
      <v-container>
        <v-sheet :min-height="window.height - 164" rounded="lg">
          <router-view></router-view>
        </v-sheet>
      </v-container>
    </v-main>

    <v-footer min-height="65px" dark padless>
      <v-card class="flex" flat tile>
        <v-card-title class="primary">
          Grupo del Valle Garbal ® - {{ new Date().getFullYear() }}
          <v-spacer></v-spacer>
          <a id="email" href="mailto:contacto@gvgarbal.com">
            contacto@gvgarbal.com
          </a>
        </v-card-title>
      </v-card>
    </v-footer>

    <v-overlay :value="loading" style="z-index:9999;">
      <v-progress-circular indeterminate size="150">
        <h2>Cargando</h2>
      </v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import logo from "./assets/logo.png";

export default {
  data() {
    return {
      logo,
      areas: [],
      mainKey: 0,
      showMain: true,
      selectedArea: "",
      links: [
        { title: "AGEBS", path: "/dashboard/agebs" },
        { title: "ELECTORAL", path: "/dashboard/electoral" },
        { title: "COMPARAR", path: "/dashboard/compare" },
        { title: "INDICES", path: "/dashboard/index" }
      ]
    };
  },
  computed: {
    window() {
      return this.$store.state.window;
    },
    loading() {
      return this.$store.state.loading;
    },
    userData() {
      return this.$store.state.userData;
    },
    mapData() {
      return this.$store.state.mapData;
    }
  },
  methods: {
    async getElectionsData() {
      if (
        this.isEmpty(this.mapData.mayorData) ||
        this.isEmpty(this.mapData.deputyData) ||
        this.isEmpty(this.mapData.sectionShape)
      ) {
        //We need to fetch the data
        this.$store.dispatch("toggleLoading");

        await this.$store.dispatch("makeAPICall", {
          url: "elecSectionShape",
          mutation: "setSectionShape"
        });
        await this.$store.dispatch("makeAPICall", {
          url: "elecAyunData",
          mutation: "setTownData"
        });
        await this.$store.dispatch("makeAPICall", {
          url: "elecDipData",
          mutation: "setDeputyData"
        });

        this.$store.dispatch("toggleLoading");
      }
    },
    async getAGEBData() {
      if (
        this.isEmpty(this.mapData.agebShapefile) ||
        this.isEmpty(this.mapData.agebData)
      ) {
        this.$store.dispatch("toggleLoading");

        await this.$store.dispatch("makeAPICall", {
          url: "agebShape",
          mutation: "setShapeFile"
        });
        await this.$store.dispatch("makeAPICall", {
          url: "agebData",
          mutation: "setAgebData"
        });
        this.$store.dispatch("toggleLoading");
      }
    },
    async getIndexData() {
      if (
        this.isEmpty(this.mapData.agebShapefile) ||
        this.isEmpty(this.mapData.indexData)
      ) {
        this.$store.dispatch("toggleLoading");

        await this.$store.dispatch("makeAPICall", {
          url: "agebShape",
          mutation: "setShapeFile"
        });
        await this.$store.dispatch("makeAPICall", {
          url: "indexData",
          mutation: "setIndexData"
        });
        this.$store.dispatch("toggleLoading");
      }
    },
    async sendTo(link) {
      switch (link.title) {
        case "ELECTORAL":
          await this.getElectionsData();
          this.mainKey++;
          break;
        case "AGEBS":
          await this.getAGEBData();
          this.mainKey++;
          break;
        case "COMPARAR":
          await this.getAGEBData();
          await this.getElectionsData();
          this.mainKey++;
          break;
        case "INDICES":
          await this.getIndexData();
          this.mainKey++;
          break;
        default:
          break;
      }
      if (this.$route.path !== link.path) this.$router.push(link.path);
    },
    logout() {
      this.$router.push("/");
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.dispatch("clearMapData");
        })
        .catch(error => {
          alert(error.message);
          this.$router.push("/");
        });
    },
    handleResize() {
      this.$store.dispatch("updateWindow", [
        window.innerWidth,
        window.innerHeight
      ]);
    },
    isEmpty(obj) {
      return obj && Object.keys(obj).length ? false : true;
    },
    async changeArea(area) {
      if (this.selectedArea === area) {
        alert("Ya estás en " + area.toUpperCase());
        return;
      }
      this.showMain = false;
      this.selectedArea = area;
      this.$store.dispatch("clearMapData");

      this.$store.dispatch("toggleLoading");

      await this.fetchMapData(area);
      if (this.$route.name === "AGEBs") {
        console.log("Getting ageb data");
        await this.$store.dispatch("makeAPICall", {
          url: "agebShape",
          mutation: "setShapeFile"
        });
        await this.$store.dispatch("makeAPICall", {
          url: "agebData",
          mutation: "setAgebData"
        });
      } else if (this.$route.name === "Electoral") {
        console.log("Getting elections data");
        await this.$store.dispatch("makeAPICall", {
          url: "elecSectionShape",
          mutation: "setSectionShape"
        });
        await this.$store.dispatch("makeAPICall", {
          url: "elecAyunData",
          mutation: "setTownData"
        });
        await this.$store.dispatch("makeAPICall", {
          url: "elecDipData",
          mutation: "setDeputyData"
        });
      } else if (this.$route.name === "Indices") {
        console.log("Getting ageb shape");
        await this.$store.dispatch("makeAPICall", {
          url: "agebShape",
          mutation: "setShapeFile"
        });
        console.log("Getting index data");
        await this.$store.dispatch("makeAPICall", {
          url: "indexData",
          mutation: "setIndexData"
        });
      } else {
        console.log("Getting both AGEB and Elections data");
        await this.$store.dispatch("makeAPICall", {
          url: "agebShape",
          mutation: "setShapeFile"
        });
        await this.$store.dispatch("makeAPICall", {
          url: "agebData",
          mutation: "setAgebData"
        });
        await this.$store.dispatch("makeAPICall", {
          url: "elecSectionShape",
          mutation: "setSectionShape"
        });
        await this.$store.dispatch("makeAPICall", {
          url: "elecAyunData",
          mutation: "setTownData"
        });
        await this.$store.dispatch("makeAPICall", {
          url: "elecDipData",
          mutation: "setDeputyData"
        });
        await this.$store.dispatch("makeAPICall", {
          url: "indexData",
          mutation: "setIndexData"
        });
      }

      this.$store.dispatch("toggleLoading");
      this.showMain = true;
      this.mainKey++;
    },
    async getAvailableAreas() {
      const db = firebase.firestore();
      const areasRef = db.collection("areas");
      const snapshot = await areasRef.get();
      snapshot.forEach(doc => {
        this.areas.push(doc.id);
      });
    },
    async fetchMapData(area) {
      let ans = false;
      const db = firebase.firestore();

      const user = db.collection("areas").doc(area);
      const doc = await user.get();
      if (doc.exists) {
        this.$store.dispatch("updateMapData", doc.data());
        ans = true;
      } else {
        alert("Error al obtener datos, comunicarse con el administrador");
      }
      return ans;
    }
  },
  beforeMount() {
    this.getAvailableAreas();
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap");

#app {
  font-family: "Lato", sans-serif;
}
a {
  text-decoration: none !important;
  font-weight: bold;
  color: black !important;
}
#email {
  color: white !important;
}
</style>
