<template>
  <v-container>
    <v-row
      no-gutters
      align-content="center"
      :style="'min-height: ' + (window.height - 190) + 'px;'"
    >
      <v-col cols="12" sm="6" align-self="center" class="d-flex justify-center">
        <v-card class="py-12 px-12" :min-width="window.height / 2" tile>
          <h2 class="mb-3">Iniciar Sesión</h2>
          <v-form @submit.prevent="login" ref="form" lazy-validation>
            <v-text-field
              class="pb-1"
              dense
              type="email"
              v-model="username"
              label="Usuario"
              required
              outlined
            ></v-text-field>

            <v-text-field
              class="pb-2"
              dense
              type="password"
              v-model="password"
              label="Contraseña"
              required
              outlined
            ></v-text-field>

            <v-btn type="submit" color="info" class="mr-4" :loading="loading">
              <strong>
                LOGIN
              </strong>
            </v-btn>
          </v-form>
        </v-card>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col cols="12" sm="6" class="d-flex justify-center align-center">
        <v-img max-width="275" :src="logo"> </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/**
 * Firebase auth: https://www.freecodecamp.org/news/how-to-add-authentication-to-a-vue-app-using-firebase/
 */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import logo from "../assets/logo.png";

export default {
  data() {
    return {
      logo,
      username: "",
      password: "",
      loading: false,
      userData: {}
    };
  },
  computed: {
    window() {
      return this.$store.state.window;
    }
  },
  methods: {
    async login() {
      let auth = false;
      this.loading = true;
      if (this.password.length > 0 && this.username.length > 0) {
        auth = await this.authenticateUser();
      } else {
        alert("Completar todos los campos");
      }
      this.loading = false;

      if (auth) {
        let status;
        status = await this.fetchUserData();

        if (!status) {
          console.log("Error getting user data");
          return;
        }

        status = await this.fetchMapData();

        if (!status) {
          console.log("Error getting maps urls");
          return;
        }
        this.$store.dispatch("toggleLoading");

        // Get the map data and shape file
        await this.$store.dispatch("makeAPICall", {
          url: "agebShape",
          mutation: "setShapeFile"
        });
        await this.$store.dispatch("makeAPICall", {
          url: "agebData",
          mutation: "setAgebData"
        });
        await this.$store.dispatch("toggleLoading");
        this.$router.push("/dashboard/agebs");
      }
    },
    async authenticateUser() {
      let ans = false;
      this.loading = true;
      await firebase
        .auth()
        .signInWithEmailAndPassword(this.username, this.password)
        .then(() => {
          ans = true;
        })
        .catch(error => {
          this.loading = false;
          alert(error.message);
        });
      return ans;
    },
    async fetchUserData() {
      let ans = false;
      const db = firebase.firestore();

      const split = this.username.split("@");
      const userId = split[0] + "-" + split[1].split(".")[0];

      const user = db.collection("users").doc(userId);
      const doc = await user.get();
      if (doc.exists) {
        this.userData = doc.data();
        this.$store.dispatch("updateUserData", doc.data());
        ans = true;
      } else {
        alert("Error con el usuario, comunicarse con el administrador");
      }
      return ans;
    },
    async fetchMapData() {
      let ans = false;
      const db = firebase.firestore();

      const user = db.collection("areas").doc(this.userData.area);
      const doc = await user.get();
      if (doc.exists) {
        this.$store.dispatch("updateMapData", doc.data());
        ans = true;
      } else {
        alert("Error al obtener datos, comunicarse con el administrador");
      }
      return ans;
    }
  }
};
</script>

<style>
.v-text-field__details {
  display: none !important;
}

button {
  text-transform: none !important;
}
</style>
