import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Login.vue";
import firebase from "firebase/app";
import "firebase/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "Grupo del Valle Garbal" }
  },
  {
    path: "/admin",
    name: "Administrador",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Administrator.vue")
  },
  {
    path: "/dashboard/agebs",
    name: "AGEBs",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/AGEBS.vue"),
    meta: {
      authRequired: true
    }
  },
  {
    path: "/dashboard/electoral",
    name: "Electoral",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/ElectoralSections.vue"
      ),
    meta: {
      authRequired: true
    }
  },
  {
    path: "/dashboard/index",
    name: "Indices",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Index.vue"),
    meta: {
      authRequired: true
    }
  },
  {
    path: "/dashboard/compare",
    name: "Comparar",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Compare.vue"),
    meta: {
      authRequired: true
    }
  },
  {
    path: "/survey",
    name: "Encuesta",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Survey.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (firebase.auth().currentUser) {
      next();
    } else {
      alert("You must be logged in to see this page");
      next({
        path: "/"
      });
    }
  } else {
    next();
  }
});

router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = to.name ? to.name : "Grupo del Valle Garbal";
  });
});

export default router;
